<template>
  <el-table
    class="components-table"
    :data="tableData"
    border
    stripe
    style="width: 100%"
    :header-row-style="headerRowStyle"
    :header-cell-style="headerCellStyle"
    :row-style="rowStyle"
  >
    <el-table-column
      show-overflow-tooltip
      :prop="item.prop"
      :label="item.label"
      :width="item.width"
      v-for="(item, index) in configTable"
      :key="index"
    >
      <template slot-scope="scope">
        <div class="number-box" v-if="item.label === '号段'">
          <span>{{ scope.row.start_num }}-{{ scope.row.stop_num }}</span>
          <div @click="toPrint(scope.row)">打印</div>
        </div>
        <div class="time-box" v-else-if="item.label === '开始时间'">
          <p>计划：{{ scope.row.planning_starttime }}</p>
          <p class="real">
            实际：{{ scope.row.start_time ? scope.row.start_time : "-" }}
          </p>
        </div>
        <div class="time-box" v-else-if="item.label === '结束时间'">
          <p>计划：{{ scope.row.planning_endtime }}</p>
          <p class="real">
            实际：{{ scope.row.end_time ? scope.row.end_time : "-" }}
          </p>
        </div>
        <p
          v-else-if="item.label === '任务状态'"
          :class="[
            'state-p',
            scope.row.state == '待执行'
              ? 'active1'
              : scope.row.state == '已完成'
              ? 'active2'
              : scope.row.state == '执行中'
              ? 'active3'
              : 'active4',
          ]"
        >
          {{ scope.row.state }}
        </p>

        <p v-else>{{ scope.row[item.prop] }}</p>

        <el-button
          plain
          size="mini"
          type="primary"
          @click="handleEdit(scope.row)"
          v-if="item.editType"
          >编辑
        </el-button>
        <el-button
          plain
          size="mini"
          type="success"
          v-if="item.completeType"
          @click="handleComplete(scope.row)"
          >完成
        </el-button>
        <el-button
          plain
          size="mini"
          type="success"
          v-if="item.startType"
          @click="handleStart(scope.row)"
          >开始
        </el-button>
        <el-button
          plain
          size="mini"
          type="info"
          @click="handleSee(scope.row)"
          v-if="item.seeType"
          >查看</el-button
        >
        <el-button
          plain
          size="mini"
          type="danger"
          @click="handleDelete(scope.row)"
          v-if="item.delType"
          >删除</el-button
        >
        <el-button
          plain
          size="mini"
          type="primary"
          @click="handleDetail(scope.row)"
          v-if="item.detailType"
          >详情</el-button
        >
        <el-button
          plain
          size="mini"
          type="danger"
          @click="handleClose(scope.row)"
          v-if="item.closeType"
          >关闭</el-button
        >
        <el-button
          plain
          size="mini"
          type="info"
          @click="handleRecovery(scope.row)"
          v-if="item.recoveryType"
          >恢复</el-button
        >
        <el-button
          plain
          size="mini"
          type="success"
          v-if="scope.row.status == '禁用' && item.enableType"
          @click="handleEnable(scope.row)"
          >启用
        </el-button>
        <el-button
          plain
          size="mini"
          type="danger"
          @click="handleForbidden(scope.row)"
          v-if="scope.row.status == '启用' && item.forbiddenType"
          >禁用</el-button
        >

        <!-- 任务按钮开始 -->
        <el-button
          plain
          size="mini"
          type="primary"
          @click="handleDetail(scope.row)"
          v-if="item.detailType2"
          >详情</el-button
        >
        <el-button
          plain
          size="mini"
          type="primary"
          @click="handleEdit(scope.row)"
          v-show="
            (scope.row.state == '待执行' && item.editType2) ||
            (scope.row.state == '已关闭' && item.editType2)
          "
          >编辑
        </el-button>
        <el-button
          size="mini"
          @click="changeStatus(scope.row)"
          type="primary"
          v-show="item.statusType"
        >
          <span v-if="scope.row.state">关闭</span><span v-else>开启</span>
        </el-button>
        <el-button
          plain
          size="mini"
          type="success"
          v-if="scope.row.state == '执行中' && item.completeType2"
          @click="handleComplete(scope.row)"
          >完成
        </el-button>
        <el-button
          plain
          size="mini"
          type="success"
          v-if="scope.row.state == '待执行' && item.startType2"
          @click="handleStart(scope.row)"
          >开始
        </el-button>
        <el-button
          plain
          size="mini"
          type="info"
          @click="handleSee(scope.row)"
          v-if="item.seeType2"
          >查看</el-button
        >
        <el-button
          plain
          size="mini"
          type="danger"
          @click="handleDelete(scope.row)"
          v-if="scope.row.state == '已关闭' && item.delType2"
          >删除</el-button
        >
        <el-button
          plain
          size="mini"
          type="danger"
          @click="handleClose(scope.row)"
          v-show="
            (scope.row.state == '执行中' && item.closeType2) ||
            (scope.row.state == '待执行' && item.closeType2)
          "
          >关闭</el-button
        >
        <el-button
          plain
          size="mini"
          type="info"
          @click="handleRecovery(scope.row)"
          v-if="scope.row.state == '已关闭' && item.recoveryType2"
          >恢复</el-button
        >
        <!-- 任务按钮结束 -->
        <!-- 批次按钮开始 -->
        <el-button
          plain
          size="mini"
          type="success"
          v-if="scope.row.custom_state == '1' && item.completeType3"
          @click="handleComplete(scope.row)"
          >完成
        </el-button>
        <el-button
          plain
          size="mini"
          type="success"
          v-if="scope.row.custom_state == '0' && item.startType3"
          @click="handleStart(scope.row)"
          >开始
        </el-button>
        <!-- 任务按钮结束 -->
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
    },
    configTable: {
      type: Array,
    },
  },
  data() {
    return {
      headerRowStyle: {
        height: "61px",
        color: "#fff",
        "background-color": "#0E2F53",
      },
      headerCellStyle: {
        color: "#fff",
        "background-color": "#0e2f53",
      },
      rowStyle: {
        height: "61px",
      },
    };
  },
  methods: {
    toPrint(item) {
      window.open(
        "https://data.darsing.net/print/print.html?oid=" +
          item.id +
          "&template_id=" +
          item.template_id
      );
    },
    handleStart(row) {
      this.$emit("handleStart", row);
    },
    handleComplete(row) {
      this.$emit("handleComplete", row);
    },
    handleEdit(row) {
      this.$emit("handleEdit", row);
    },
    changeStatus(row) {
      this.$emit("changeState", row);
    },
    handleDetail(row) {
      this.$emit("handleDetail", row);
    },
    handleClose(row) {
      this.$emit("handleClose", row);
    },
    handleSee(row) {
      this.$emit("handleSee", row);
    },
    handleRecovery(row) {
      this.$emit("handleRecovery", row);
    },
    handleDelete(row) {
      this.$emit("handleDelete", row);
    },
    handleEnable(row) {
      this.$emit("handleEnable", row);
    },
    handleForbidden(row) {
      this.$emit("handleForbidden", row);
    },
  },
};
</script>
<style lang="less">


.el-table {
  color: #fff;
  margin-bottom: 24px;
  flex: initial;
  .number-box {
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      width: 70px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      cursor: pointer;
      background: #3e90e5;
      border-radius: 3px;
      margin-left: 16px;
      font-size: 12px;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .time-box {
    display: flex;
    justify-content: center;
    flex-direction: column;

    p {
      margin-bottom: 10px;
    }

    .real {
      color: #67c23a;
      margin-bottom: 0px;
    }
  }
  .state-p {
    width: 58px;
    height: 24px;
    border-radius: 2px;
    font-size: 12px;
    color: #fff;
    &.active1 {
      background-color: #faad14;
    }
    &.active2 {
      background-color: #67c23a;
    }
    &.active3 {
      background-color: #3e90e5;
    }
    &.active4 {
      background-color: #f56c6c;
    }
  }
}

.el-table .el-table__cell {
  text-align: center;
}



.el-table tr {
  background-color: #092139;
}

.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
  background-color: #071a2c;
}

.el-table--border,
.el-table--group {
  border-color: #385576;
}

.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-color: #385576;
}

.el-table--border::after,
.el-table--group::after,
.el-table::before {
  background-color: #385576;
}

.el-table tbody tr:hover > td {
  background: rgba(56, 85, 118, 0.5) !important;
}

.el-button--primary.is-plain {
  background-color: inherit;
  border-color: rgba(62, 144, 229, 0.4);
  color: #3e90e5;
}

.el-button--primary.is-plain:hover {
  background-color: rgb(62, 144, 229);
  color: #fff;
}

.el-button--success.is-plain {
  background-color: inherit;
  border-color: rgba(103, 194, 58, 0.4);
  color: #67c23a;
}

.el-button--success.is-plain:hover {
  background-color: rgb(103, 194, 58);
  color: #fff;
}

.el-button--info.is-plain {
  background-color: inherit;
  border-color: rgba(62, 184, 229, 0.4);
  color: rgb(62, 184, 229);
}

.el-button--info.is-plain:hover,
.el-button--info.is-plain:active,
.el-button--info.is-plain:focus {
  background-color: rgb(62, 184, 229);
  border-color: rgb(62, 184, 229);
  color: #fff;
}

.el-button--danger.is-plain {
  background-color: inherit;
  border-color: rgba(245, 108, 108, 0.4);
  color: #f56c6c;
}

.el-button--danger.is-plain:hover {
  background-color: rgb(245, 108, 108);
  color: #fff;
}
</style>
